import React, { useEffect, useState } from "react";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/lang";
import AppCss from "./App.css";
import BootstrapRTL from "bootstrap/dist/css/bootstrap.rtl.min.css";
import BootstrapLTR from "bootstrap/dist/css/bootstrap.min.css";
import { IntlProvider } from "react-intl";
import Header from "./Components/Global/Header";
import Home from "./Components/Homepage/Home";
import Contact from "./Components/Contact/Contact";
import News from "./Components/News/News";
import NewsDetails from "./Components/News/NewsDetails";
import NotFound from "./Components/NotFound/NotFound";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  const pathname = window.location.pathname;
  const splitLocation = pathname.split("/");
  const defLang = splitLocation[1] || "en";
  const locale = defLang === "en" ? LOCALES.ENGLISH : LOCALES.ARABIC;
  const textDirection = defLang === "en" ? "ltr" : "rtl";
  const [currentMenuItem, SetCurrMenuItem] = useState(null);
  const [isMenuPage, SetMenuPage] = useState(false);

  const menuclickeditem = (id) => {
    SetCurrMenuItem(id);
  };

  const onMobileMenuPage = (boolean) => {
    SetMenuPage(boolean);
  };

  useEffect(() => {
    document.body.dir = textDirection;
  }, []);

  return (
    <IntlProvider
      messages={messages[locale]}
      locale={defLang}
      defaultLocale="en"
    >
      <div className="App">

        <Router>
          {!isMenuPage && (
            <Header
              logo="red"
              currentLocale={defLang}
              textDirection={textDirection}
              menuclickeditem={menuclickeditem}
            />
          )}
          <Routes>
            {/* Redirect from old URL to new URL   */}
            <Route path="/en/news" element={<Navigate to="/en" replace />} />
            <Route path="/ar/news" element={<Navigate to="/ar" replace />} />
            
            <Route path="/" element={<Navigate to="/en" />} exact />
            <Route
              path="/:locale"
              exact="true"
              element={
                <Home
                  textDirection={textDirection}
                  currentLocale={defLang}
                  currentMenuItem={currentMenuItem}
                  onMobileMenuPage={onMobileMenuPage}
                />
              }
            />
            <Route
              path="/:locale/contact"
              exact="true"
              element={
                <Contact
                  textDirection={textDirection}
                  currentLocale={defLang}
                />
              }
            />
            <Route
              path="/:locale/news"
              element={
                <News textDirection={textDirection} currentLocale={defLang} />
              }
            />
            <Route
              path="/:locale/news/:id/:alias"
              element={
                <NewsDetails
                  textDirection={textDirection}
                  currentLocale={defLang}
                />
              }
            />
            <Route
              path="*"
              element={
                <NotFound
                  textDirection={textDirection}
                  currentLocale={defLang}
                />
              }
            />
          </Routes>
        </Router>
      </div>
    </IntlProvider>
  );
}

export default App;
